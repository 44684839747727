import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./scss/styles.scss";
import "./i18n";
import App from "./App";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Suspense fallback="loading...">
        <App />
      </Suspense>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import { useState } from "react";
import HomePage from "./Pages/HomePage/HomePage";
import CalendarPage from "./Pages/CalendarPage/CalendarPage";
import LoginPage from "./Pages/LoginPage/LoginPage";

import { ContentContext, i18nContext } from "./Context.jsx";
import { AuthProvider } from "./AuthContext.jsx";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AudioPlayerProvider } from "react-use-audio-player";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import LanguagePicker from "./components/LanguagePicker/LanguagePicker";

function App() {
  const { t, i18n } = useTranslation(["translation"]);
  const [isLogIn, setIsLogIn] = useState(false);

  const handleLogin = (newValue) => {
    setIsLogIn(newValue);
  };

  return (
    <AudioPlayerProvider>
      <AuthProvider>
        <i18nContext.Provider value={i18n}>
          <LanguagePicker />
          <ContentContext.Provider
            value={{
              homePageDatas: t("homePage"),
              calendarPageDatas: t("calendarPageDatas"),
              loginPageDatas: t("LoginPage")
            }}
          >
            <Router>
              <Switch>
                <Route path="/login">
                  <LoginPage onChange={handleLogin} token={isLogIn} />
                </Route>
                <ProtectedRoute>
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/calendar" component={CalendarPage} />
                </ProtectedRoute>
              </Switch>
            </Router>
          </ContentContext.Provider>
        </i18nContext.Provider>
      </AuthProvider>
    </AudioPlayerProvider>
  );
}
export default App;

import React, { useState, useEffect, useContext } from "react";
import { ContentContext } from "../../Context";

import CalendarCard from "../../components/CalendarCard/CalendarCard";
import Modal from "../../components/Modal/Modal";

import { DateTime } from "luxon";
import { useAudioPlayer } from "react-use-audio-player";
import audio from "../../assets/Sounds/CalendarSound.mp3";

function CalendarPage() {
  const { calendarPageDatas } = useContext(ContentContext);

  const [isOpen, setIsOpen] = useState(false);
  const [showText, setShowText] = useState(false);
  const [modal, setModal] = useState({ modalInfo: "", writer: "", date: "" });
  const [modalBackground, setmodalBackground] = useState({ modalBackground: "" });
  const currentDateTime = DateTime.local();

  const { day, month, year } = currentDateTime.c;
  const currentDate = day;
  const currentMonth = month;
  const currentYear = year;

  const { togglePlayPause, stop } = useAudioPlayer({
    src: audio,
    format: "mp3",
    autoplay: false,
  });

  const closeModal = () => {
    setShowText(false);
    setIsOpen(false);
    stop();
  };

  const openWindow = (calendarDate, calendarMonth, calendarYear, text, writer, date, imageName) => {
    setModal({ modalInfo: text, writer, date });
    setmodalBackground({ modalBackground: imageName });
    setIsOpen(true);
  };

  const handleHide = () => {
    setShowText(!showText);
  };

  useEffect(() => {
    if (isOpen) {
      togglePlayPause();
      setTimeout(() => {
        setShowText(true);
      }, 3500);
    }
  }, [isOpen, togglePlayPause]);

  return (
    <div className="calendarPage-container">
      <div className="calendarPage-background">
        <div>
          <Modal handleOpen={isOpen} onClose={closeModal} modalBackground={modalBackground} showContent={showText}>
            <div className={showText ? "show-modal-content" : "modal-content-hidden"} onClick={handleHide}>
              {showText ? (
                <div>
                  <h2 className="modal-date">{modal.date}</h2>
                  <p className="modal-text">{modal.modalInfo}</p>
                  <p>{modal.writer}</p>
                </div>
              ) : null}
            </div>
          </Modal>
          <div className="calendar">
            {calendarPageDatas.map(
              ({ calendarDate, calendarMonth, calendarYear, width, height, text, writer, date, imageName }, i) => {
                return (
                  <div className="calendarCard" key={i}>
                    <CalendarCard
                      date={calendarDate}
                      cardWidth={width}
                      cardHeight={height}
                      onClick={() =>
                        openWindow(calendarDate, calendarMonth, calendarYear, text, writer, date, imageName)
                      }
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarPage;

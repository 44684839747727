import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationEN from "./locales/en/translation.json";
import translationSV from "./locales/sv/translation.json";

const resources = {
  sv: {
    translation: translationSV,
  },
  en: {
    translation: translationEN,
  },
};

i18n.use(detector).use(initReactI18next).init({
  keySeparator: false,
  nsSeparator: false,
  compatibilityJSON: "v2",
  resources,
  fallbackLng: "sv",
  defaultNS: "translation",
  ns: "translation",
  returnObjects: true,
});

export default i18n;

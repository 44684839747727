import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import AuthContext from "../../AuthContext";

const ProtectedRoute = ({ children }) => {
  const { authToken } = useContext(AuthContext);

  if (!authToken) return <Redirect to="/login" />;
  return <div>{children}</div>;
};

export default ProtectedRoute;

import { useState, useContext } from "react";
import { i18nContext } from "../../Context";

const LanguagePicker = () => {
  const i18n = useContext(i18nContext);
  const i18nLanguage = i18n.language;
  const [currenti18nLanguage, setCurrenti18nLanguage] = useState(i18nLanguage);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setCurrenti18nLanguage(language);
  };

  return (
    <div className="language-container">
      <select onChange={(event) => changeLanguage(event.target.value)} value={currenti18nLanguage}>
        <option value="en">English</option>
        <option value="sv">Swedish</option>
      </select>
    </div>
  );
};

export default LanguagePicker;

const Logos = {
  snallkalendern_logo: require("../assets/images/homePage/snall-logo.svg").default,
  bunny_image: require("../assets/images/homePage/bunny.svg").default,
  friends_logo: require("../assets/images/homePage/friends_logo.svg").default,
  tietoevry_logo: require("../assets/images/homePage/tietoevry_logo.svg").default,
  mrs_mighetto_logo: require("../assets/images/homePage/mighetto.svg").default,
  girls_image: require("../assets/images/homePage/girls.svg").default,
};

export default Logos;
